<template>
	<div class="label-list">
		<el-form :inline="true" class="form-content">
			<el-form-item>
				<el-input placeholder="请输入图表名称关键字查询" v-model="title" class="input-with-select"  style="width:240px;">
					<el-button slot="append" @click="getList" icon="el-icon-search"></el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="适用群体">
			<el-cascader class="wd400" v-model="group" :options="groupOptions" :props="props"
				@change="handleChange"></el-cascader>
			</el-form-item>
			<!-- <el-form-item label="创建时间">
        <el-date-picker
          v-model="dateRangeTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item> -->
			<el-form-item>
				<el-button type="primary" class="ml20" @click="getList">搜索</el-button>
				<el-button class="ml20" @click="reset">重置</el-button>
			</el-form-item>
		</el-form>
		<div class="search-list">
			<el-button type="primary" @click="add">添加</el-button>
			<el-button type="danger" @click="deleteHandle"
				:disabled="dataListSelections.length ? false : true">删除</el-button>
		</div>
		<div class="table">
			<el-table :data="dataList" style="width: 100%" v-loading="dataListLoading"
				@selection-change="selectionChangeHandle">
				<el-table-column type="selection" width="50"></el-table-column>
				<el-table-column  min-width="120" prop="chartName" label="图表名称">
					<template slot-scope="scope">
						<el-button type="text" @click="goInfo(scope.row)">{{scope.row.chartName}}</el-button>

					</template>
				</el-table-column>


				<el-table-column  min-width="300" prop="createName" label="指标项">
					<template slot-scope="scope">
						<span v-for="item in scope.row.dataIndicators">{{item.infoName}}-{{item.cellName}}; </span>

					</template>
				</el-table-column>
				<el-table-column min-width="100" prop="labelTypeName" label="使用群体"><template slot-scope="scope">
						<span v-if="scope.row.dateType == 1">全部</span>
						<span v-if="scope.row.dateType == 2">按分组</span>
						<span v-if="scope.row.dateType == 3">按标签</span>
					</template></el-table-column>

				<el-table-column width="100" prop="orderWeight" label="排序权重"></el-table-column>
				<el-table-column width="170" prop="createTime" label="添加时间"></el-table-column>


				<el-table-column width="100" prop="updateTime" label="图标预览">
					<template slot-scope="scope">
						<el-button type="text" @click="showChart(scope.row)">预览</el-button>

					</template>
				</el-table-column>
				<el-table-column width="120" label="操作" header-align="right" align="right">
					<template slot-scope="scope">
						<el-button type="text" @click="goedit(scope.row)">编辑</el-button>
						<el-button type="text" @click="changeStatus(scope.row,1)"
							v-if="scope.row.status == 3">启用</el-button>
						<el-button type="text" @click="changeStatus(scope.row,3)"
							v-if="scope.row.status == 1">禁用</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
				:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
				layout="total, sizes, prev, pager, next, jumper"></el-pagination>
		</div>
		<el-dialog title="图表预览" :close-on-click-modal="false" :visible.sync="dialogVisible" width='800px'>
			<div id="myChart" class="mychart">

			</div>
			<p style="float: left;color: #67BF63;">*仅提供样式预览，非真实数据</p>
		</el-dialog>
	</div>
</template>
<script>
	import * as echarts from "echarts";
	import Qs from "qs";
	export default {
		name: "label",
		data() {
			return {
				props: {
					multiple: true
				},
				form: {
					title: "",
					dateType: [],
					labelId: [],
						groupingId: [],
					
				
				},
			groupOptions: [{
					 value: 1,
					label: '全部',
					children: [{
					 value: '全部',
					label: '全部',}
				 ]
				},
				{
					value: 2,
					label: '按分组',
					children: [],
				}, {
					value: 3,
					label: '按标签',
					children: [],
				}
			
			
			],
				group: "",
				title: "",
				status: "",
				dataList: [],
				dataListLoading: true, //列表loading
				dataListSelections: [], //选中的列
				pageNo: 1,
				pageSize: 10,
				totalPage: 0, //总页数
				dateRangeTime: [],
				teamsManage: [],
				typeList: [{
						name: "待检索",
						value: 1
					},
					{
						name: "检索中",
						value: 3
					},
					{
						name: "检索完成",
						value: 4
					},
				],
				visible: false,
				 
				dataRules: {
					title: [{
						required: true,
						message: "请输入任务主题",
						trigger: "blur"
					}],
				},
				edit: false,
				flag: true, //防止重复点击
				visible2: false,
				dialogVisible: false,
				dataList2: [],
				dataListLoading2: true, //列表loading
				dataListSelections2: [], //选中的列
				pageNo2: 1,
				pageSize2: 10,
				totalPage2: 0, //总页数
				retrievalId: ""
			};
		},
		async mounted() {
			await this.getList();
			await this.getGroupList()
			await this.getGroupList2()
		},
		methods: {
			handleChange(arr) {
				console.log(arr, "v");
				this.form.labelId = []
					this.form.groupingId = []
						this.form.dateType = []
					for (let item of arr) {
						if(item[0]==1){
							this.form.dateType = [1]
						}
					}
					console.log('this.form.dateType');
					console.log(this.form.dateType);
			  {
			
					let flag1 = false
					let flag2 = false
					for (let itemArr of arr) {
									
										if (itemArr[0] == 2) {
											flag1 = true
					this.form.groupingId.push(itemArr[1])
										}
										if (itemArr[0] == 3) {
											flag2 = true
											this.form.labelId.push(itemArr[1])
										}
									}
				
					if (flag1) {
						this.form.dateType.push(2)
					}
					if (flag2) {
						this.form.dateType.push(3)
					}
			
				}
			
			
				
			},
			async getGroupList() {
				const user = JSON.parse(localStorage.getItem("userInfo"));
				let teamsId = "";
				let enertyId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
					enertyId = JSON.parse(localStorage.currentTeam).entityId;
				}
				let params = {
					
					stringParam11: teamsId,
					intParam1: 1000,
					intParam2: 1,
					intParam3: 1,
				};
				const {
					data: res
				} = await this.$httpAes({

					url: this.$httpAes.adornUrl("/mbglLabel/list"),
					method: "post",
					data: params,
				});
				if (res.status) {
					res.data.forEach(item => {
						item.label = item.labelName
						item.value = item.id
					})
					this.groupingList = res.data
			
			
					this.groupOptions[1].children = res.data;
				}
			},
			async getGroupList2() {
				const user = JSON.parse(localStorage.getItem("userInfo"));
				let teamsId = "";
				let enertyId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
					enertyId = JSON.parse(localStorage.currentTeam).entityId;
				}
				let params = {
					
					stringParam11: teamsId,
					intParam1: 1000,
					intParam2: 1,
					intParam3: 2,
				};
				const {
					data: res
				} = await this.$httpAes({

					url: this.$httpAes.adornUrl("/mbglLabel/list"),
					method: "post",
					data: params,
				});
				if (res.status) {
			
					res.data.forEach(item => {
						item.label = item.labelName
						item.value = item.id
					})
					this.groupingList2 = res.data
					this.groupOptions[2].children  = res.data;
				}
			},
			randomSort(a, b) {
				return Math.random() > 0.5 ? -1 : 1;
			},
			showChart(row) {
				var that = this
				this.dialogVisible = true

				let seriesArr = []
				let xAxisList = [
					"2023-11-02 13:57:35",
					"2023-11-02 14:47:50",
					"2023-11-02 15:00:03",
					"2024-01-17 10:23:06",
					"2024-01-17 10:23:20",
					"2024-01-17 11:07:56",
					"2024-01-17 11:08:07",
					"2024-01-17 11:08:15",
					"2024-01-17 11:08:26"
				]
				for (let item of row.dataIndicators) {
					var a = [10, 11, 6, 9,11, 10, 12, 7, 14];
					let seriesData = a.sort(this.randomSort);




					let obj = {
						"seriesData": seriesData,
						"name": item.chartDisplayName,
						"unitName": item.unitName,
					}
						seriesArr.push(obj)
				}
			setTimeout(()=>{
				let dom = document.getElementById("myChart");
				let myChart = echarts.init(dom); // 初始化echarts实例
				that.option = {
					title: {
						text: row.chartName,
						left: "center",
					},
					grid: {
						//与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
				
						top: "10%",
				
						left: "8%",
				
						right: "8%",
				
						bottom: "19%",
					},
					// tooltip: {
					// 	trigger: 'axis',
				
					// },
					//提示框
					tooltip: {
						trigger: "axis",
						formatter: (params) => {
							console.log(params);
							console.log(seriesArr);
							var relVal = "";
							for (var i = 0, l = params.length; i < l; i++) {
								if (params[i].value) {
									relVal +=
										seriesArr[i].name +
										" " +
										params[i].marker +
										" " +
										params[i].value +
										" " +
										seriesArr[i].unitName +
										"<br/>";
								} else {
									relVal +=
										seriesArr[i].name +
										" " +
										params[i].marker +
										" " +
										"无数据" +
										" " +
										seriesArr[i].unitName +
										"<br/>";
								}
							}
							return relVal;
						},
					},
				
					legend: {
						data: [],
						bottom:10
					},
				
					xAxis: {
						type: "category",
				
						data: xAxisList,
						boundaryGap: false,
					},
					yAxis: {
						type: "value",
						// max: 20,
						min: 0,
						splitNumber: 4,
					},
					toolbox: {
						feature: {
							saveAsImage: {},
						},
					},
					dataZoom: [{
						type: "inside",
						xAxisIndex: [0],
						start: 0,
						end: 100,
					}, ],
					series: [],
				};
				for (let item of seriesArr) {
					let obj = {
						name: item.name,
						data: item.seriesData,
						type: "line",
						smooth: true,
						unitName: item.unitName,
						connectNulls: true,
						areaStyle: {
						      normal: {
						        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
						        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						          {
						            offset: 0,
						            color: "#EAF6FF", // 0% 处的颜色
						          },
						          {
						            offset: 1,
						            color: "#C3E6FF", // 100% 处的颜色
						          },
						        ]), //背景渐变色
						      },
						    },
					
					};
				
					//	unitName:dataIndicator.unitName,
				
					that.option.series.push(obj);
					that.option.legend.data.push(item.name);
				}
				
				// 设置实例参数
				myChart.setOption(that.option);
			},1000)
				
			},
			async getList() {
				let teamsId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
				}
			
				let params = {
					chartName:this.title,
					teamsId: teamsId,
					 dateType:this.form.dateType.join(','),
					 labelId:this.form.labelId.join(','),
					 	groupingId:this.form.groupingId.join(','),
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};

				const {
					data: res
				} = await this.$http({
					url: this.$http.adornUrl("/indicatorChart/list"),
					method: "get",
					params: params,
				});
				if (res.status) {
					this.dataList = res.data;
					this.totalPage = res.totalCount;
				}
				this.dataListLoading = false;
			},
			goedit(item) {
				sessionStorage.chartInfo = JSON.stringify(item)
				this.$router.push({
					name: "chartEdit",
				});
			},
			goInfo(item) {
				sessionStorage.chartInfo = JSON.stringify(item)
				this.$router.push({
					name: "chartInfo",
				});
			},
			add(item) {
				console.log('add')
				this.$router.push({
					name: "chartAdd",
				});
				// if (item.id) {
				//   this.$router.push({
				//     name: "addCaseSearch",
				//     query: { id: item.id },
				//   });
				// } else {
				//   this.$router.push({
				//     name: "addCaseSearch",
				//   });
				// }
			},
			cancel() {
				this.$refs["form"].resetFields();
				this.visible = false;
				this.form = {
					title: "",
				};
			},
			async changeStatus(row, status) {
				var params = {
					ids: row.id,
					status: status
				}
				const {
					data: res
				} = await this.$http({
					url: this.$http.adornUrl(`/indicatorChart/updateStatusByIds`),
					method: "post",
					// headers: {
					//   "Content-Type": "application/json",
					//   token: this.$cookie.get("token")
					// },
					data: Qs.stringify(params),
				});
				console.log(res);
				if (res.status) {
					this.$message({
						message: "修改成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.getList()
						},
					});
				}
			},
			async submit() {
				let params = {
					title: this.form.title,
					// status: 1,
				};
				if (localStorage.currentTeam) {
					params.teamsId = JSON.parse(localStorage.currentTeam).id;
				}
				if (this.form.id) {
					params.id = this.form.id;
				}
				// console.log(params);
				this.$refs.form.validate(async (valid) => {
					if (this.flag) {
						this.flag = false;
						if (valid) {
							const {
								data: res
							} = await this.$httpAes({
								url: this.$httpAes.adornUrl(
									this.form.id ? "/mbglLabelIndex/update" :
									"/mbglLabelIndex/add"
								),
								method: "post",
								data:params,
							});
							if (res.status) {
								this.$message({
									message: res.msg,
									type: "success",
									duration: 1500,
									onClose: () => {
										this.flag = true;
										this.visible = false;
										this.getList();
										if (res.data && res.data) {
											this.$router.push({
												name: "addCaseSearch",
												query: {
													id: res.data
												},
											});
										}
									},
								});
							} else {
								this.$message.error(res.msg);
								this.flag = true;
							}
						} else {
							return false;
						}
					}
				});
			},
			async deleteHandle() {
				this.$confirm("确定进行[删除分组]操作", "提示", {
						confirmButtonText: "确定",
						cancelButtonClass: "btn-custom-cancel",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(async () => {
						const ids = [];
						this.dataListSelections.forEach((v) => {
							ids.push(v.id);
						});
						let str = "";
						if (ids.length) {
							str = ids.join(",");
						}
						let params = {
							ids: str,
							status: 2
						};
						const id = ids.join(`,`);
						const {
							data: res
						} = await this.$http({
							url: this.$http.adornUrl("/indicatorChart/updateStatusByIds"), 
							method: "post",
							data: Qs.stringify(params),
						});
						if (res.status) {
							this.$message({
								message: res.msg,
								type: "success",
								duration: 1500,
								onClose: () => {
									this.getList();
								},
							});
						}
					})
					.catch(() => {});
			},
			async getTypeList() {
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/dict/list"),
					method: "post",
					data: {
						stringParam1: "标签类型",
					},
				});
				// console.log(res,'res')
				this.typeList = res.data;
			},
			reset() {
				this.title = "";
				this.labelName = "";
				this.labelType = "";
				 this.form.dateType= [];
				 this.form.labelId= [];
				 this.form.groupingId= [];
				this.group = ''
				this.dateType= []
				this.labelId= []
					this.groupingId= []
				this.dateRangeTime = [];
				this.isGrouping = "";
				this.getList();
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val;
				this.pageNo = 1;
				this.getList();
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageNo = val;
				this.getList();
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val;
			},
			// 显示下载列表
			downloadData(row) {
				this.visible2 = true;
				this.retrievalId = row.id;
				this.getDataList();
			},
			// 下载内容
			async getDataList() {
				let params = {
					intParam1: this.pageSize2,
					intParam2: this.pageNo2,
					stringParam1: this.retrievalId,
				};
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/mbglRetrieveRecords/list"),
					method: "post",
					data: params,
				});
				if (res.status) {
					this.dataList2 = res.data;
					this.totalPage2 = res.totalCount;
				}
				this.dataListLoading2 = false;
			},
			// 每页数
			sizeChangeHandle2(val) {
				this.pageSize2 = val;
				this.pageNo2 = 1;
				this.getDataList();
			},
			// 当前页
			currentChangeHandle2(val) {
				this.pageNo2 = val;
				this.getDataList();
			},
			// 添加检索
			addQuestionSearch(row) {
				let params = {
					retrievalId: row.id
				};
				this.$httpAes({
					url: this.$httpAes.adornUrl("/mbglRetrieveRecords/add"),
					method: "post",
					data:params,
				}).then(({
					data
				}) => {
					if (data.status) {
						this.updateStatus(row.id);
					}
				});
			},
			updateStatus(id) {
				let params = {
					stringParam1: id,
					intParam1: 3
				};
				this.$httpAes({
					url: this.$httpAes.adornUrl("/mbglLabelIndex/updateStatusByIds"),
					method: "post",
					data:params,
				}).then(({
					data
				}) => {
					if (data.status) {
						this.$message({
							message: "重新检索成功",
							type: "success",
							duration: 1500,
							onClose: () => {
								this.$router.push({
									name: "caseSearch",
								});
							},
						});
					}
				});
			},
			// 下载
			downloadUrl(row) {
				var that = this;
				that.updateNum(row);
			},
			// 更新下载次数
			updateNum(row) {
				// 添加检索
				let params = {
					id: row.id
				};
				if (row.number) {
					params.number = row.number + 1;
				} else {
					params.number = 1;
				}
				this.$httpAes({
					url: this.$httpAes.adornUrl("/mbglRetrieveRecords/update"),
					method: "post",
					data: params,
				}).then(({
					data
				}) => {
					if (data.status) {
						let link = document.createElement("a");
						let url = "";
						if (row.url) {
							url = "https://" + row.url.substr(13);
						}
						link.href = url;
						link.click();
						this.getDataList()
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.mychart {
	  width: 100%;
	  height: 500px;
	}
</style>